<template>
    <div>
        <div class="pricing">
            <div class="pricing-content shadow-2">
                <div class="pricing-title text-900">Get Started With</div>
                <div class="pricing-subtitle">PrimeBlocks</div>
                <p class="m-0">Choose the right plan for your business. Whether you are an individual or a member of a team, PrimeBlocks are available for affordable prices.</p>
            </div>
        </div>
        <div class="px-0 lg:px-8 pricing-inner">
            <p class="mb-3 text-center text-lg">All licences share same terms and only differ in per-seat limits, visit the <a href="https://www.primefaces.org/blocks/licenses" class="text-blue-500 no-underline">license</a> page for terms and conditions.</p>
            <div class="flex flex-wrap">
                <div class="w-full lg:w-4 p-3">
                    <div class="shadow-2 p-4 surface-0 border-blue-500 border-2" style="border-radius:6px">
                        <div class="text-2xl text-900 mb-3 font-medium">Single Developer</div>
                        <p class="text-600 mb-4">For individual developers</p>
                        <div class="py-4 text-900 font-medium text-5xl border-top-1 border-bottom-1 border-300">$99</div>
                        <div class="pt-4 pb-0">
                            <ul class="list-none p-0 m-0">
                                <li class="flex align-items-center mb-3">
                                    <span class="border-circle bg-blue-500 text-white flex align-items-center justify-content-center flex-shrink-0" style="width:1.5rem;height:1.5rem">
                                        <i class="pi pi-check"></i>
                                    </span>
                                    <span class="font-bold text-700 ml-2">1 Developer</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <span class="border-circle bg-blue-500 text-white flex align-items-center justify-content-center flex-shrink-0" style="width:1.5rem;height:1.5rem">
                                        <i class="pi pi-check"></i>
                                    </span>
                                    <span class="text-600 ml-2">All Marketing + Application UI Blocks</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <span class="border-circle bg-blue-500 text-white flex align-items-center justify-content-center flex-shrink-0" style="width:1.5rem;height:1.5rem">
                                        <i class="pi pi-check"></i>
                                    </span>
                                    <span class="text-600 ml-2">Figma UI Kit</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <span class="border-circle bg-blue-500 text-white flex align-items-center justify-content-center flex-shrink-0" style="width:1.5rem;height:1.5rem">
                                        <i class="pi pi-check"></i>
                                    </span>
                                    <span class="text-600 ml-2">Lifetime Support</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <span class="border-circle bg-blue-500 text-white flex align-items-center justify-content-center flex-shrink-0" style="width:1.5rem;height:1.5rem">
                                        <i class="pi pi-check"></i>
                                    </span>
                                    <span class="text-600 ml-2">Unlimited Updates</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <span class="border-circle bg-blue-500 text-white flex align-items-center justify-content-center flex-shrink-0" style="width:1.5rem;height:1.5rem">
                                        <i class="pi pi-check"></i>
                                    </span>
                                    <span class="text-600 ml-2">Use on Unlimited Projects</span>
                                </li>
                            </ul>
                            <a href="https://www.primefaces.org/store/blocks.xhtml" class="border-round no-underline bg-blue-500 text-center hover:bg-blue-600 text-white block w-full p-3 mt-8 font-medium
                                transition-colors transition-duration-150">Buy Now</a>
                        </div>
                    </div>
                </div>
                <div class="w-full lg:w-4 p-3">
                    <div class="shadow-2 p-4 surface-0 border-orange-500 border-2" style="border-radius:6px">
                        <div class="text-2xl text-900 mb-3 font-medium">Team</div>
                        <p class="text-600 mb-4">For small teams</p>
                        <div class="py-4 text-900 font-medium text-5xl border-top-1 border-bottom-1 border-300">$249</div>
                        <div class="pt-4 pb-0">
                            <ul class="list-none p-0 m-0">
                                <li class="flex align-items-center mb-3">
                                    <span class="border-circle bg-orange-500 text-white flex align-items-center justify-content-center flex-shrink-0" style="width:1.5rem;height:1.5rem">
                                        <i class="pi pi-check"></i>
                                    </span>
                                    <span class="font-bold text-700 ml-2">Up to 5 Developers</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <span class="border-circle bg-orange-500 text-white flex align-items-center justify-content-center flex-shrink-0" style="width:1.5rem;height:1.5rem">
                                        <i class="pi pi-check"></i>
                                    </span>
                                    <span class="text-600 ml-2">All Marketing + Application UI Blocks</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <span class="border-circle bg-orange-500 text-white flex align-items-center justify-content-center flex-shrink-0" style="width:1.5rem;height:1.5rem">
                                        <i class="pi pi-check"></i>
                                    </span>
                                    <span class="text-600 ml-2">Figma UI Kit</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <span class="border-circle bg-orange-500 text-white flex align-items-center justify-content-center flex-shrink-0" style="width:1.5rem;height:1.5rem">
                                        <i class="pi pi-check"></i>
                                    </span>
                                    <span class="text-600 ml-2">Lifetime Support</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <span class="border-circle bg-orange-500 text-white flex align-items-center justify-content-center flex-shrink-0" style="width:1.5rem;height:1.5rem">
                                        <i class="pi pi-check"></i>
                                    </span>
                                    <span class="text-600 ml-2">Unlimited Updates</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <span class="border-circle bg-orange-500 text-white flex align-items-center justify-content-center flex-shrink-0" style="width:1.5rem;height:1.5rem">
                                        <i class="pi pi-check"></i>
                                    </span>
                                    <span class="text-600 ml-2">Use on Unlimited Projects</span>
                                </li>
                            </ul>
                            <a href="https://www.primefaces.org/store/blocks.xhtml" class="border-round no-underline bg-orange-500 text-center hover:bg-orange-600 text-white block w-full p-3 mt-8 font-medium
                                transition-colors transition-duration-150">Buy Now</a>
                        </div>
                    </div>
                </div>
                <div class="w-full lg:w-4 p-3">
                    <div class="shadow-2 p-4 surface-0 border-bluegray-500 border-2" style="border-radius:6px">
                        <div class="text-2xl text-900 mb-3 font-medium">Enterprise</div>
                        <p class="text-600 mb-4">For larger teams</p>
                        <div class="py-4 text-900 font-medium text-5xl border-top-1 border-bottom-1 border-300">Exclusive Deals</div>
                        <div class="pt-4 pb-0">
                            <ul class="list-none p-0 m-0">
                                <li class="flex align-items-center mb-3">
                                    <span class="border-circle bg-bluegray-500 text-white flex align-items-center justify-content-center flex-shrink-0" style="width:1.5rem;height:1.5rem">
                                        <i class="pi pi-check"></i>
                                    </span>
                                    <span class="font-bold text-700 ml-2">Unlimited Developers</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <span class="border-circle bg-bluegray-500 text-white flex align-items-center justify-content-center flex-shrink-0" style="width:1.5rem;height:1.5rem">
                                        <i class="pi pi-check"></i>
                                    </span>
                                    <span class="text-600 ml-2">All Marketing + Application UI Blocks</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <span class="border-circle bg-bluegray-500 text-white flex align-items-center justify-content-center flex-shrink-0" style="width:1.5rem;height:1.5rem">
                                        <i class="pi pi-check"></i>
                                    </span>
                                    <span class="text-600 ml-2">Figma UI Kit</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <span class="border-circle bg-bluegray-500 text-white flex align-items-center justify-content-center flex-shrink-0" style="width:1.5rem;height:1.5rem">
                                        <i class="pi pi-check"></i>
                                    </span>
                                    <span class="text-600 ml-2">Lifetime Support</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <span class="border-circle bg-bluegray-500 text-white flex align-items-center justify-content-center flex-shrink-0" style="width:1.5rem;height:1.5rem">
                                        <i class="pi pi-check"></i>
                                    </span>
                                    <span class="text-600 ml-2">Unlimited Updates</span>
                                </li>
                                <li class="flex align-items-center mb-3">
                                    <span class="border-circle bg-bluegray-500 text-white flex align-items-center justify-content-center flex-shrink-0" style="width:1.5rem;height:1.5rem">
                                        <i class="pi pi-check"></i>
                                    </span>
                                    <span class="text-600 ml-2">Use on Unlimited Projects</span>
                                </li>
                            </ul>
                            <a href="mailto:contact@primetek.com.tr" class="border-round no-underline bg-bluegray-500 text-center hover:bg-bluegray-600 text-white block w-full p-3 mt-8 font-medium
                                transition-colors transition-duration-150">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pricing-users bg-bluegray-700 text-center py-6 px-4 lg:px-8 pricing-inner">
            <div class="text-white text-4xl mb-3">User Community</div>
            <p class="mt-0 p-0 mb-6 text-white line-height-3">The creator of PrimeVue is <a href="https://www.primetek.com.tr" class="text-white">PrimeTek Informatics</a>, the distinguished component library vendor
                who built vastly popular open source projects such as PrimeFaces, PrimeNG, PrimeReact and PrimeVue over the past years.</p>
            <div class="grid">
                <div class="col-6 md:col-2 p-3"><img src="../../assets/images/pricing/airbus.svg"></div>
                <div class="col-6 md:col-2 p-3"><img src="../../assets/images/pricing/amex.svg"></div>
                <div class="col-6 md:col-2 p-3"><img src="../../assets/images/pricing/nvidia.svg"></div>
                <div class="col-6 md:col-2 p-3"><img src="../../assets/images/pricing/ebay.svg"></div>
                <div class="col-6 md:col-2 p-3"><img src="../../assets/images/pricing/ford.svg"></div>
                <div class="col-6 md:col-2 p-3"><img src="../../assets/images/pricing/fox.svg"></div>
                <div class="col-6 md:col-2 p-3"><img src="../../assets/images/pricing/lufthansa.svg"></div>
                <div class="col-6 md:col-2 p-3"><img src="../../assets/images/pricing/mercedes.svg"></div>
                <div class="col-6 md:col-2 p-3"><img src="../../assets/images/pricing/sap.svg"></div>
                <div class="col-6 md:col-2 p-3"><img src="../../assets/images/pricing/unicredit.svg"></div>
                <div class="col-6 md:col-2 p-3"><img src="../../assets/images/pricing/verizon.svg"></div>
                <div class="col-6 md:col-2 p-3"><img src="../../assets/images/pricing/viacom.svg"></div>
            </div>
        </div>
        <div class="px-4 lg:px-8 pricing-inner">
            <div class="text-900 text-4xl mb-6 text-center">Frequently Asked Questions</div>
            <div class="grid">
                <div class="col-12 lg:col-4 px-2 lg:px-5">
                    <div class="text-2xl text-900 line-height-3 mb-2">What do I get when I purchase a license?</div>
                    <p class="mt-0 mb-6 p-0 line-height-3 text-700">You'll be able to download this application as a Vue CLI project that unlocks all the code views along with the full source code.</p>

                    <div class="text-2xl text-900 line-height-3 mb-2">Is there a recurring fee or is the license perpetual?</div>
                    <p class="mt-0 mb-6 p-0 line-height-3 text-700">PrimeBlocks license is perpetual so required one time payment not subscription based.</p>

                    <div class="text-2xl text-900 line-height-3 mb-2">Can I use PrimeBlocks license for commercial projects?</div>
                    <p class="mt-0 mb-6 p-0 line-height-3 text-700">Yes, your license allows you to sell your projects that utilize PrimeBlocks.</p>

                    <div class="text-2xl text-900 line-height-3 mb-2">Can I create multiple projects for multiple clients?</div>
                    <p class="mt-0 mb-6 p-0 line-height-3 text-700">There is no limit, you are able to use PrimeBlocks in multiple projects for multiple clients.</p>

                    <div class="text-2xl text-900 line-height-3 mb-2">Does PrimeBlocks include Figma, Sketch, or Adobe XD files?</div>
                    <p class="mt-0 mb-6 p-0 line-height-3 text-700">PrimeBlocks uses Figma as the design tool. It will be possible to download the Figma file after your purchase. You can <a href="https://www.figma.com/file/wamKwldl2lmophg0D8DXTi/Preview-%7C-PrimeBlocks-3.1.0?node-id=0%3A1" class="text-blue-500 no-underline hover:underline">preview the Figma file</a> before the purchase.</p>
                </div>
                <div class="col-12 lg:col-4 px-2 lg:px-5">
                    <div class="text-2xl text-900 line-height-3 mb-2">We're a reseller, are we able to purchase a license on behalf of our client?</div>
                    <p class="mt-0 mb-6 p-0 line-height-3 text-700">Yes, after the purchase, please <a href="mailto:contact@primetek.com.tr" class="text-blue-500 no-underline hover:underline">contact us</a> so we can transfer the license to your client.</p>

                    <div class="text-2xl text-900 line-height-3 mb-2">Does the enterprise license include contractors within the organization?</div>
                    <p class="mt-0 mb-6 p-0 line-height-3 text-700">Yes, contractors are also able to use the PrimeBlocks within your company.</p>

                    <div class="text-2xl text-900 line-height-3 mb-2">Can subsidiary company of a larger organization share the enterprise license?</div>
                    <p class="mt-0 mb-6 p-0 line-height-3 text-700">No, enterprise license is per company so each subsidiary company needs to purchase a separate license.</p>

                    <div class="text-2xl text-900 line-height-3 mb-2">What does "free updates" mean?</div>
                    <p class="mt-0 mb-6 p-0 line-height-3 text-700">All updates including new designs and blocks will be totally free of charge for existing customers.</p>

                    <div class="text-2xl text-900 line-height-3 mb-2">Is there a maintenance period for PrimeBlocks and the support?</div>
                    <p class="mt-0 mb-6 p-0 line-height-3 text-700">No, updates are free of charge and support is available for unlimited period.</p>
                </div>
                <div class="col-12 lg:col-4 px-2 lg:px-5">
                    <div class="text-2xl text-900 line-height-3 mb-2">How can I get support?</div>
                    <p class="mt-0 mb-6 p-0 line-height-3 text-700">Support is provided by PrimeTek via
                            <a href="https://forum.primefaces.org/viewforum.php?f=152" class="text-blue-500 no-underline hover:underline">a dedicated forum channel monitored</a> by PrimeTek support staff.</p>

                    <div class="text-2xl text-900 line-height-3 mb-2">What does lifetime support mean?</div>
                    <p class="mt-0 mb-6 p-0 line-height-3 text-700">Support service at the forum does not have a time limit.</p>

                    <div class="text-2xl text-900 line-height-3 mb-2">Can I use PrimeBlocks in open-source projects?</div>
                    <p class="mt-0 mb-6 p-0 line-height-3 text-700">Due to the license, it is not possible to use the blocks in an open source project where code is publicly available.</p>

                    <div class="text-2xl text-900 line-height-3 mb-2">What JS framework does PrimeBlocks use?</div>
                    <p class="mt-0 mb-6 p-0 line-height-3 text-700">Each Prime UI component library has its own PrimeBlocks version, PrimeBlocks for Vue uses PrimeVue.</p>

                    <div class="text-2xl text-900 line-height-3 mb-2">What browsers does PrimeBlocks support?</div>
                    <p class="mt-0 mb-6 p-0 line-height-3 text-700">PrimeBlocks supports Edge, Firefox, Safari and Chrome.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Pricing'
}
</script>
